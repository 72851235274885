import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import HomeClientsSection from '../components/homepage/homeclients'
import HomeNewsletterSection from '../components/homepage/homenewsletter'
import ServicesHeroSection from '../components/servicespage/serviceshero'
// import ServicesFirstSection from '../components/servicespage/servicesfirst'
// import ServicesSecondSection from '../components/servicespage/servicessecond'
// import ServicesThirdSection from '../components/servicespage/servicesthird'
import ServicesPackagesSection from '../components/servicespage/servicespackages'
// import DownloadableContent from '../components/servicespage/downloadablecontent'

const ServicesPage = () => {
    return (
        <Layout>
            <SEO title="Services | Digital Community building &amp; nurturing" />
            <ServicesHeroSection />
            {/* <DownloadableContent /> */}
            <ServicesPackagesSection />
            {/* <ServicesFirstSection />
            <ServicesSecondSection />
            <ServicesThirdSection /> */}
            <HomeClientsSection />
            <HomeNewsletterSection />

        </Layout>
    )
}

export default ServicesPage