import React from 'react'
import { Link } from "gatsby"
import homeServices from '../css_modules/homeservices.module.scss'
import globalStyle from '../css_modules/global.module.scss'
import servicesMain from '../css_modules/servicesmain.module.scss'
import wrapupsSection from '../css_modules/wrapupssection.module.scss'
import servicesPackages from '../css_modules/servicespackages.module.scss'

const ServicesPackagesSection = () => {
    return (
        <section className={homeServices.homeServicesSection}>
            <div className={homeServices.sectionLeft}>
                <div className={globalStyle.container}>
                    <h2 className={globalStyle.heroTitle}>our<br /><label>packages</label></h2>
                </div>
                <div className={globalStyle.grayBackground + ' ' + servicesMain.grayBackground}>
                    <div className={globalStyle.container}>
                        <div className={globalStyle.servicesContent}>
                            <p>We customise our marketing packages to suit your business goals, helping your prepare your organisation for the digital future. We recommend the options below as the first step to growing your digital business.</p>
                        </div>
                    </div>
                    <div className={globalStyle.container}>
                        <div className={homeServices.servicesList + ' ' + servicesPackages.directionColumn}>
                            <ul className={servicesPackages.packagesWrapper}>
                                <li className={wrapupsSection.entry + ' ' + servicesPackages.box}>
                                    <div className={servicesPackages.blueOverlay}></div>
                                    <div className={servicesPackages.boxFace}>
                                        <h3>Build your <br />audience</h3>
                                        <p>No digital product can survive without a digital community. Start building your audience online.</p>
                                    </div>
                                    <div className={servicesPackages.boxOverlay}>
                                        <p><center><label>Includes</label></center></p>
                                        <p>
                                            <ul>
                                                <li>Build a content strategy</li>
                                                <li>Build a channel strategy</li>
                                                <li>Content Production Delivery (videos, pictures, texts, formats for different channels etc)</li>
                                                <li>Ad Delivery</li>
                                            </ul>
                                        </p>
                                        <center><button><Link to="contact">I want this package!</Link></button></center>
                                    </div>
                                </li>
                                <li className={wrapupsSection.entry + ' ' + servicesPackages.box}>
                                    <div className={servicesPackages.blueOverlay}></div>
                                    <div className={servicesPackages.boxFace}>
                                        <h3>Build your<br />footprint</h3>
                                        <p>Are you searchable, reachable and can people engage with your content? Start building your digital footprint</p>
                                    </div>
                                    <div className={servicesPackages.boxOverlay}>
                                        <p><center><label>Includes</label></center></p>
                                        <p>
                                            <ul>
                                                <li>Social Media strategy</li>
                                                <li>Digital Community Strategy</li>
                                                <li>Contact forms</li>
                                                <li>Community forums blogs for people to engage with your content.</li>
                                            </ul>
                                        </p>
                                        <center><button><Link to="contact">I want this package!</Link></button></center>
                                    </div>
                                </li>
                            </ul>
                            <ul className={servicesPackages.packagesWrapper}>
                                <li className={wrapupsSection.entry + ' ' + servicesPackages.box}>
                                    <div className={servicesPackages.blueOverlay}></div>
                                    <div className={servicesPackages.boxFace}>
                                        <h3>Build your <br /> business model</h3>
                                        <p>Start building your business model and generating revenue through digital marketing and tools.</p>
                                    </div>
                                    <div className={servicesPackages.boxOverlay}>
                                        <p><center><label>Includes</label></center></p>
                                        <p>
                                            <ul>
                                                <li>Support in digital product/tool  building and IT projects</li>
                                                <li>Digital product strategy - applications, platforms, portals, websites</li>
                                                <li>Design, concept and implementation of roll out strategy</li>
                                                <li>Business development and marketing strategy for your services/products</li>
                                                <li>Lead generation strategy - sales, funnel building, targeting.</li>
                                            </ul>
                                        </p>
                                        <center><button><Link to="contact">I want this package!</Link></button></center>
                                    </div>
                                </li>
                                <li className={wrapupsSection.entry + ' ' + servicesPackages.box}>
                                    <div className={servicesPackages.blueOverlay}></div>
                                    <div className={servicesPackages.boxFace}>
                                        <h3>Build your<br />systems</h3>
                                        <p>Make MarTech and SMarketing work for you by connecting your marketing systems to one-key data journey.</p>
                                    </div>
                                    <div className={servicesPackages.boxOverlay}>
                                        <p><center><label>Includes</label></center></p>
                                        <p>
                                            <ul>
                                                <li>Aligning your teams (IT, Marketing and Sales)</li>
                                                <li>White label solution</li>
                                                <li>Coding, programming, CMS, Interfaces that work</li>
                                                <li>Implementation of marketing technologies</li>
                                                <li>Marketing action automation.</li>
                                            </ul>
                                        </p>
                                        <center><button><Link to="contact">I want this package!</Link></button></center>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default ServicesPackagesSection