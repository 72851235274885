import React from 'react'
import homeHero from '../css_modules/homehero.module.scss'
import globalStyle from '../css_modules/global.module.scss'
import servicesHero from '../css_modules/serviceshero.module.scss'
import { Link } from "gatsby"

const ServicesHeroSection = () => {
    return (
        <section className={homeHero.heroSectionHome + ' ' + servicesHero.heroSectionServices}>
            <div className={globalStyle.container}>
                <h1 className={globalStyle.h1Hero}>Grow your digital business with<br /><label> our services</label></h1>
            </div>
            <div className={homeHero.heroSectionContent}>
                <div className={homeHero.imgPlaceholder + ' ' + servicesHero.imgPlaceholder}>

                </div>
                <div className={homeHero.contentRight}>
                    <p>We connect Marketing & IT to enable digital products and services to find customers, bind communities and establish technologies to prepare for the future!</p>
                    <Link to="contact" className={globalStyle.mainButton}>Talk to us</Link>
                </div>
            </div>
        </section >
    )
}

export default ServicesHeroSection